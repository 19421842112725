import { ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router/composables"

export const PAGE_QUERY_PARAM = "page"

export function usePaginationWithRouter() {
  const route = useRoute()
  const router = useRouter()
  const _page = ref(parseInt(`${route.query[PAGE_QUERY_PARAM] || 1}`))

  const page = computed({
    get() {
      return _page.value
    },
    /**
    * @param {number} pageN
    */
    set(pageN) {
      const newQuery = { ...route.query }

      if (pageN <= 1) {
        delete newQuery[PAGE_QUERY_PARAM]
      } else {
        newQuery[PAGE_QUERY_PARAM] = pageN.toString()
      }

      router.replace({ ...route, query: newQuery })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err
          }
        })

      _page.value = pageN
    }
  })

  const setPageN = (pageN) => {
    page.value = pageN
  }

  return {
    page,
    setPageN,
  }
}

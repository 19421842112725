import { ref, computed, onBeforeMount } from "vue"
import { useRouter, useRoute } from "vue-router/composables"

export const SEARCH_PARAM_NAME = "search"
export const MIN_SEARCH_LENGTH = 3

export function useSearchInputWithRouter({ minSearchLength = MIN_SEARCH_LENGTH } = {}) {
  /**
   * @type {import("vue").Ref<string>}
   */
  const search = ref("")
  const route = useRoute()
  const router = useRouter()

  const searchWithRouter = computed({
    get() {
      return search.value
    },
    set(searchStr) {
      if (searchStr.length && searchStr.length < minSearchLength) {
        search.value = searchStr
        return
      }

      const newQuery = { ...route.query }

      if (searchStr) {
        newQuery[SEARCH_PARAM_NAME] = searchStr
      } else {
        delete newQuery[SEARCH_PARAM_NAME]
      }

      router.replace({ ...route, query: newQuery })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err
          }
        })

      search.value = searchStr
    }
  })

  const searchAsReqParam = computed(() => {
    if (search.value.length < minSearchLength) {
      return ""
    }
    return search.value
  })

  onBeforeMount(() => {
    search.value = `${route.query[SEARCH_PARAM_NAME] || ""}`
  })


  return {
    search: searchWithRouter,
    searchAsReqParam,
  }
}
